<div class="container-fluid" *ngIf="place">
  <app-page-title
    title="{{title}}"
    subTitle="{{subTitle}}"
    image="https://api.hanadiv.org/assets/Icons_Enterprise_Grey.svg"
    rowID="{{place.placeID}}"
    createDate="{{place.placeCreateTimeStamp}}"></app-page-title>
  <form [formGroup]="editPlaceForm" (ngSubmit)="onSubmit()">
    <!-- step 1 -->
    <div>
      <label class="mt-3">
        <span>תיאור המקום</span>
        <textarea type="text" class="form-control" formControlName="description"></textarea>
      </label>
      <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.description.valid">נא להזין את תיאור המקום</div>
      <label>
        <span>עיר</span>
        <select class="form-control" formControlName="cityID" (change)="onSelectCity()">
          <option value="0">יישוב</option>
          <option *ngFor="let city of cities" [ngValue]="city.cityID">
            {{ city.title }}
          </option>
        </select>
      </label>
      <div class="color-red mb-3" *ngIf="formSubmitted && editPlaceForm.controls.cityID.value==0">נא לבחור יישוב</div>

      <div *ngIf="editPlaceForm.controls.cityID.value">
        <form [formGroup]="neighborhoodSearchForm">
          <label class="mt-3">
            <input type="text" class="form-control" placeholder="חיפוש שכונה" formControlName="search" (keyup)="resetNeighborhoodSelect()">
          </label>
        </form>
        <div *ngIf="!selectedNeighborhood && neighborhoodSearchForm.controls.search.value.length>2">
          <div *ngFor="let item of neighborhoods | filterNeighborhoods:neighborhoodSearchForm.value.search" (click)="onSelectNeighborhood(item)">
            <div>{{item.title}}</div>
          </div>
        </div>
      </div>

      <label class="mt-3">
        <span>שם הרחוב</span>
        <input type="text" class="form-control" formControlName="street">
      </label>
      <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.street.valid">נא להזין את שם הרחוב</div>
      <label class="mt-3">
        <span>מספר בית</span>
        <input type="number" class="form-control" formControlName="houseNumber">
      </label>
      <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.houseNumber.valid">נא להזין את מספר הבית</div>
      <label class="mt-3">
        <span>מספר כניסה</span>
        <input type="text" class="form-control" formControlName="entrance">
      </label>
    </div>

    <!-- step 2-->
    <div>
      <div class="col-12 mt-3 pr-0">
        <div>כמה אנשים יכולים להיות במקום?</div>
        <mv-slider [(value)]="place.maxNumberOfPeople" [min]="minimumParticipants" [max]="maximumParticipants"></mv-slider>
        <div>{{place.maxNumberOfPeople}}</div>
      </div>
      <div class="form-group mt-4" *ngIf="dynamicFormData">
        <div class="group-title mt-3 mr-3">איפה זה קורה?</div>
        <label *ngFor="let item of dynamicFormData.areaSettings" class="group-item mt-2 mr-3 pb-2">
          <input type="radio" value="{{item.value}}" class="border-greyish" name="areaSettingValue" formControlName="areaSettingValue">
          <span class="pr-2">{{item.title}}</span>
        </label>
        <div class="color-red mb-3" *ngIf="formSubmitted && !editPlaceForm.controls.areaSettingValue.valid">נא לבחור אפשרות</div>
      </div>
      <div class="form-group mt-4 p-4">
        <span>יש חניה</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="hasParking">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>נגיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="isAccessible">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>ידידותי לבעלי חיים</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="petFriendly">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>אפשר להרעיש</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="canHostNoisyEvents">
          <span class="slider round"></span>
        </label>
        <hr>
        <span>יש פסנתר</span>
        <label class="switch float-left">
          <input type="checkbox" formControlName="piano">
          <span class="slider round"></span>
        </label>
      </div>
      <div class="mt-4">
        <label>
          <span class="font-size-17">הערות מיוחדות?</span>
          <textarea class="mt-2 form-control border-greyish" formControlName="notes" placeholder="יש חניה בשפע, רישום מיוחד בוויז או דרכי זיהוי מיוחדים, כמו הבית שלנו הוא עם הגדר הירוקה"></textarea>
        </label>
      </div>
    </div>

    <!-- step 3 -->
    <div>
      <app-file-upload title="תמונה של מקום האירוח" userID="{{place.hostID}}" subdomain="{{place.festivalEnglishName}}"></app-file-upload>
      <div class="col-10 m-auto text-center" *ngIf="imageSource">
        <img [src]="imageSource" class="image rounded img-thumbnail" alt="תמונת האירוע">
      </div>
    </div>

    <!-- step 4 -->
    <div>
      <div class="row mt-4 text-center font-size-17">
        <div class="col pb-3 select-date-box pointer" *ngFor="let item of dateRange" (click)="selectDate(item.fullDate)" [class.selected-date]="item.fullDate===selectedDate">
          <div>{{item.day}}</div>
          <div>{{item.dayMonth}}</div>
        </div>
      </div>
      <div class="row select-hours-box mt-4 justify-content-center">
        <div class="hour mr-2 mb-2 p-2 pointer"
             *ngFor="let item of hours"
             (click)="selectHour(item)"
             [class.selected-hour]="isHourSelected(item)">
          {{getNumberAsHour(item)}}
        </div>
      </div>

      <div *ngIf="formSubmitted && numberOfSelectedAvailableHours===0" class="color-red mb-1">נא לסמן מתי המקום פנוי לארח</div>
      <div *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value>numberOfSelectedAvailableHours && numberOfSelectedAvailableHours>0" class="color-red mb-1">
        <div>נא לציין מספר חלונות זמן (שעות) כמספר היוזמות שאתה מוכן לארח</div>
        <div>
          <span>בחרת </span>
          <span>{{numberOfSelectedAvailableHours}}</span>
          <span *ngIf="numberOfSelectedAvailableHours===1"> שעה </span>
          <span *ngIf="numberOfSelectedAvailableHours>1"> שעות </span>
          <span>לאירוח ו-</span>
          <span>{{editPlaceForm.controls.numberOfInitiativesWillingToHost.value}}</span>
          <span *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value===1"> יוזמה </span>
          <span *ngIf="editPlaceForm.controls.numberOfInitiativesWillingToHost.value>1"> יוזמות </span>
          <span>לאירוח</span>
        </div>
      </div>


      <label for="select-number-of-initiatives" class="mt-3 font-size-17">כמה יוזמות אתם מוכנים לארח בפסטיבל?</label>
      <select id="select-number-of-initiatives" class="form-control mt-2" formControlName="numberOfInitiativesWillingToHost">
        <option *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]">
          {{ item }}
        </option>
      </select>
    </div>

    <div *ngIf="formSubmitted" class="mt-3">
      <div class="color-red mb-3" *ngIf="!editPlaceForm.controls.description.valid || !editPlaceForm.controls.street.valid || !editPlaceForm.controls.houseNumber.valid || editPlaceForm.controls.cityID.value==0">
        נא למלא את כל שדות החובה בטופס
      </div>
    </div>

    <div class="text-center mt-4 mb-5">
      <button class="btn btn-submit w-50 font-size-18 pr-3 pl-3 w-75" type="submit">שמירה</button>
    </div>
    <div class="text-center mt-5 mb-10">
      <button class="btn btn-delete font-size-18 pr-3 pl-3 w-75" type="button" (click)="delete()">
        <fa-icon [icon]="faTrash" class="float-right"></fa-icon>
        <span>מחיקה</span>
      </button>
    </div>
  </form>
</div>
